import React, { useState, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { useTheme, makeStyles, darken } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { useStaticQuery, graphql } from 'gatsby';
import IndexLayout from '../layouts';
import {
  Appbar,
  SafeAreaContainer,
  Footer,
  SVGArt,
  OfferingCard,
} from '../components';
import HERO_IMAGE from '../brand/TOGETHER.png';
import { FluidObject } from 'gatsby-image';

interface StyleProps {
  containerHeight: number | string;
}

const ITEMS_PER_PAGE = 4;

const useStyles = makeStyles((theme) => ({
  category: {
    color: theme.palette.primary.main,
  },
  frontMatterGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderBottomColor: theme.palette.secondary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
  },
  backmatterGrid: {
    borderTopColor: theme.palette.secondary.main,
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  applyBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  spacer: {
    minHeight: theme.spacing(4),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  trianglifySVG: {
    width: '100%',
    height: '100vh',
  },
  heroButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    fontSize: 22,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.3),
    },
  },
  heroImgDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  brandVideoDiv: {
    [theme.breakpoints.up('md')]: {
      marginTop: '-64px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-56px',
    },
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
  },
  diagonalSvg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '13vw',
    zIndex: 5,
  },
  brandHeaderDiv: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: (props: StyleProps) => props.containerHeight,
    zIndex: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  brandVideo: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vh',
    },
    display: 'flex',
  },
  headline: {
    '&:before': {
      // content: "''",
      display: 'inline-block',
      [theme.breakpoints.up('sm')]: {
        width: '3rem',
        height: '3rem',
      },
      [theme.breakpoints.down('xs')]: {
        width: '1rem',
        height: '1rem',
      },
      backgroundColor: theme.palette.secondary.main,
      backgroundPositionX: '0%',
      borderRadius: '50%',
      backgroundPositionY: '0%',
      backgroundRepeat: 'repeat',
      backgroundAttachment: 'scroll',
      backgroundImage: 'none',
      backgroundSize: 'auto',
      backgroundOrigin: 'padding-box',
      backgroundClip: 'border-box',
      marginRight: '0.625rem',
    },
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(4),
  },
  brandHeader: {
    zIndex: 5,
  },
  brandHeaderInner: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.contrastText,
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  bg: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type YAMLType = {
  allOfferingsYaml: {
    edges: {
      node: {
        id: string;
        title: string;
        description: string;
        raised: string;
        investors: string;
        daysleft: string;
        mininvest: string;
        valuation: string;
        price_per_share: string;
        shares_offered: string;
        offering_type: string;
        offering: string;
        status: string;
        picture: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      };
    }[];
  };
};

const IndexPage: React.FC = () => {
  const [showPopup, setShowPopup] = useState(true);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { ref: measuredRef, height = 1 } = useResizeObserver<HTMLDivElement>();
  const classes = useStyles({ containerHeight: height - (xs ? 56 : 64) });
  // without useMemo, this would re-render with every...
  // well, with every re-render of this landing page
  // aside from performance implications, it would be very
  // noticeable to the user because the shapes and colors would change
  const renderSVG = useMemo(
    () => <SVGArt height="100%" width="100%" ratio={1} />,
    [],
  );

  const { allOfferingsYaml: yaml }: YAMLType = useStaticQuery(graphql`
    {
      allOfferingsYaml {
        edges {
          node {
            id
            title
            description
            raised
            investors
            daysleft
            mininvest
            valuation
            price_per_share
            shares_offered
            offering_type
            offering
            status
            picture {
              ...sixteenToNineImage
            }
          }
        }
      }
    }
  `);

  const [page, setPage] = useState(0);

  return (
    <IndexLayout>
      <Appbar />
      <SafeAreaContainer className={classes.bg}>
        <div ref={measuredRef} className={classes.brandVideoDiv}>
          {renderSVG}
          {/* <svg
            id="trianglify-svg-element"
            ref={trianglifySVGElm}
            className={classes.trianglifySVG}
          /> */}
          {/*
            height = 1 is the initial value,
            we don't want to return the brand header immediately because
            it would flicker
          */}
          {height > 1 && (
            <div className={classes.brandHeaderDiv}>
              <Container className={classes.brandHeader}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant={xs ? 'h5' : 'h3'}
                      className={classes.headline}
                    >
                      ¡Bienvenido a nuestra plataforma de crowdfunding!
                    </Typography>
                    <Typography
                      variant={xs ? 'body2' : 'h5'}
                      className={classes.subtitle}
                    >
                      Tenemos las mejores ofertas...
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.heroImgDiv}>
                    <img src={HERO_IMAGE} width="90%" alt="Hero" />
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
        </div>
        <Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={showPopup}
            onClose={() => setShowPopup(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showPopup}>
              <div className={classes.paper}>
                <Typography variant="h2" id="transition-modal-title">
                  ¡Espera!
                </Typography>
                <div className={classes.spacer} />
                <Typography id="transition-modal-description">
                  ¿Te esperabas este pop-up? No tiene ningún sentido,
                  puedes cerrarlo.
                </Typography>
                <div className={classes.spacer} />
                <Button
                  onClick={() => setShowPopup(false)}
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Entendido
                </Button>
              </div>
            </Fade>
          </Modal>

          <div className={classes.spacer} />
          <Typography variant="h3" color="primary" gutterBottom>
            Nuestras ofertas
          </Typography>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            {!showPopup
              && yaml.edges.slice(0, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map((item) => (
                <Grid
                  item
                  key={item.node.id}
                  xs={12}
                  md={4}
                  lg={3}
                  className={classes.gridPadding}
                >
                  <OfferingCard offering={item.node} />
                </Grid>
              ))}
          </Grid>

          <div className={classes.spacer} />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => setTimeout(() => setPage((prev) => (prev + 1)), 1500)}
            disabled={yaml.edges.length <= (page * ITEMS_PER_PAGE + ITEMS_PER_PAGE)}
          >
            Cargar más
          </Button>

          {/* spacer */}
          <div className={classes.spacer} />
        </Container>
      </SafeAreaContainer>
      <Footer />
    </IndexLayout>
  );
};

export default IndexPage;
